import HolidaySale, { RoomPackageDiscount } from '@hooks/useHolidaySale/HolidaySale';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import { IRoomType } from '@models/Room/i-room-type';

export type PACKAGE_TYPES = 'mini' | 'full' | 'at home' | 'merch';
export type RoomType = IRoomType | undefined;
export type BannerText = 'mini only' | 'best match' | '';

export enum PACKAGES {
  FULL = 1,
  MINI = 2,
  AT_HOME = 6,
  MERCH = -1
}

export enum PACKAGE_NAMES {
  MINI = 'mini',
  FULL = 'full',
  AT_HOME = 'at home',
  MERCH = 'merch',
}

export const PACKAGE_DETAILS = {
  full: {
    title: 'Online Design',
    description: 'Partner online with one of our talented designers to bring your dream home to life - any time and any place.',
    locations: []
  },
  'at home': {
    title: 'At-Home Design',
    description: 'A full-service experience in your home. Your designer will assess your space and create a comprehensive design for your home.',
    locations: ['Austin', 'Boston', 'Charlotte', 'Chicago', 'Dallas', 'Denver', 'Houston', 'Los Angeles', 'New York', 'Philadelphia', 'Seattle', 'Washington DC']
  },
};

export type PRICE_TYPE = 'msrp' | 'sale' | 'addon';

export type Prices = {
  [K in PACKAGE_TYPES]: {
    [P in PRICE_TYPE]: number;
  };
};

export const PRICES: Prices = {
  full: {
    msrp: 199,
    sale: 159,
    addon: 119,
  },
  mini: {
    msrp: 129,
    sale: 99,
    addon: 99,
  },
  'at home': {
    msrp: 699,
    sale: 499,
    addon: 199
  },
  merch: {
    msrp: 0,
    sale: 0,
    addon: 0
  }
};

export function salePackageDiscount(type: string, holidaySale?: HolidaySale):
  RoomPackageDiscount | undefined {
  return holidaySale?.isActive()
    ? holidaySale?.roomPackageDiscount(type.toUpperCase())
    : undefined;
}

export function miniTypeRoomSelected(selectedRoom: RoomType, inMiniRemovalExperiment = false) {
  if (!inMiniRemovalExperiment && selectedRoom) {
    return ['Kitchen', 'Bathroom'].includes(selectedRoom?.type);
  }

  return false;
}

export function trackPackageClick(name: PACKAGE_TYPES) {
  analyticsTrack(
    'package_card_clicked',
    {
      packageType: name,
    },
  );
}
export const handleCTAClick = (
  CTA: ((props: any) => void) | undefined,
  type: PACKAGE_TYPES
) => {
  if (!CTA) return null;

  trackPackageClick(type);
  switch (type) {
    case 'full': {
      return CTA(PACKAGES.FULL);
    }
    case 'mini': {
      return CTA(PACKAGES.MINI);
    }
    case 'at home': {
      return CTA(PACKAGES.AT_HOME);
    }
    case 'merch': {
      return CTA(PACKAGES.MERCH);
    }
    default: return CTA(PACKAGES.FULL);
  }
};

export interface IBasePackageCardProps {
  CTA?: (props: any) => void;
  CTASolidVariation?: boolean;
  bannerText?: BannerText;
  bgColor: string;
  deliverables?: JSX.Element[];
  headline?: JSX.IntrinsicElements[keyof JSX.IntrinsicElements];
  holidaySale: HolidaySale;
  imageUrl?: string;
  initExpanded?: boolean;
  isMiniOnly?: boolean;
  isNarrow?: boolean;
  isSelected?: boolean;
  mobileVariationLayout?: boolean;
  renderTitleInside?: boolean;
  showImage?: boolean;
  title: string;
  useCase: string;
  withDetails?: boolean;
}
